<template>
  <div class="slider__wrapper" :class="deviceType">
    <v-carousel hide-delimiters>
      <v-carousel-item
        v-for="(photo, i) in photos"
        :key="i"
        :src="photo.image"
        :cycle="true"
      >
        <div class="slider__on_text">
          <p>{{ photo.title }}</p>
        </div>
      </v-carousel-item>
    </v-carousel>
    <slick ref="slick" :options="slickOptions" class="slider">
      <div
        v-for="(photo, i) in photos"
        :key="i"
        class="slider__item"
        :style="'background-image: url(' + photo.photo + ')'"
      >
        <div class="slider__on_text">
          <p>{{ photo.title }}</p>
        </div>
      </div>
    </slick>
  </div>
</template>

<script>
export default {
  name: "Slider",
  props: {
    photos: {
      type: Array,
      default: () => [],
    },
    deviceType: {
      type: String,
      default: "pc",
    },
  },
  data: () => ({
    slickOptions: {
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToScroll: 1,
      slidesToShow: 1,
      fade: true,
      speed: 2000,
      centerMode: true,
      centerPadding: "0",
    },
  }),
};
</script>

<style lang="scss">
.slider {
  .slick-arrow {
    position: absolute;
    top: 50%;
    z-index: 1002;
    display: inline-block;
    width: 50px;
    height: 50px;
    text-indent: -99999px;
    cursor: pointer;
    outline: none;
    transform: rotate(45deg) translateY(-50%);
  }
  .slick-prev {
    left: 55px;
    border-bottom: 2px solid #fff;
    border-left: 2px solid #fff;
  }
  .slick-next {
    right: 55px;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
  }
  &__wrapper {
    &.pc {
      .slick-slide {
        width: 100% !important;
        max-width: 1024px !important;
      }
    }
    &.sp {
      .slick-arrow {
        display: none;
      }
      .slick-slide {
        width: 425px !important;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.slider {
  width: 100%;
  margin: 0 auto;
  &__item {
    position: relative;
    width: 100%;
    height: calc(70vh - 66px);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
  &__on_text {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    padding: 10px;
    color: #fff;
    text-align: right;
    background: rgba(0, 0, 0, 0.7);
  }
  &__wrapper {
    margin: 0 auto;
    &.pc {
      max-width: 1024px;
    }
    &.sp {
      max-width: 425px;
      .slider__item {
        height: calc(70vh - 105px);
      }
      .slider__on_text {
        text-align: center;
      }
    }
  }
}
</style>
